.job--container{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 70px;
}
.job--container .job--header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.job--container .job--header .company--logo{
    margin-right: 1rem;
    cursor: pointer;
}
.job--container .job--header .company--logo img{
    width: 3rem;
    border-radius: 20px;
}
.job--container .job--header .job-brief{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
.job--container .job--header .job--title{

}
.job--container .job--header .job--title h1{
    font-size: 1em;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.job--container .job--header .job--controls{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3rem;
    width: 4%;
}
.job--container .job--header--desc{
    display: flex;
    flex-direction: row;
}
.job--container .job--header--desc span{
    margin-right: 1rem
}
.job--container .job--type{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}
.job--container .job--type svg{
    margin-right: 1rem;
    width: 1rem;
}
.job--container .company--size{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}
.job--container .company--size svg{
    margin-right: 1rem;
    width: 1rem;
}

.job--container .job--details{
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 1rem;
}

.job--container .job--details .job--desc{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #FFFFFF;
    padding: 2rem 2rem;
    border: 0.5px solid #f3f3f3;
    align-items: center;
    border-radius: 10px;
}

.job--container .job--details .job--desc .job--desc--text{
    width: 70%
}
.job--container .job--details .job--desc .job--desc--text h6{
    font-weight: 600;
}

.job--container .job--details .job--desc .job--responsabilities{
    margin-top: 1rem;
    width: 70%
}
.job--container .job--details .job--desc .job--responsabilities h6{
    font-weight: 600;
}

.job--container .job--details .job--desc .job--requirements{
    width: 70%
}
.job--container .job--details .job--desc .job--requirements h6{
    font-weight: 600;
}

.job--container .job--details .job--desc .job--benefits{
    width: 70%
}
.job--container .job--details .job--desc .job--benefits h6{
    font-weight: 600;
}




.job--container .job--details .job--details--controls{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 5%;
}

.share--btn, .menu--btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 0.5px solid #f3f3f3;
    cursor: pointer;
    margin-bottom: 1rem;
}

.share--btn:hover, .menu--btn:hover{
    background-color: rgb(60, 140, 177);;
    color:#FFFFFF
}