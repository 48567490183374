.landing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.landing .landing--search{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    margin-top: 2rem;
}

.landing .landing--search input {
    width: 50rem;
}
.landing .landing--search .btn{
    width: 5rem;
}