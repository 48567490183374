
/* Path: src/components/Logo/Logo.css */
.app--icon{
    color: rgb(60, 140, 177);
    /* #EE2F48; */
    margin-right: 10px;
}

.app-title{
    cursor: pointer;
}
.app-title .ein{
    color: rgb(60, 140, 177);
    margin: 5px 0;
}

