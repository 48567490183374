
.app-title{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  color: #1D2435;
}

.version{
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  color: #A9B2C4;
}

.active{
  color: #EE2F48;
}

.h100 {
  height: 100%;
}

.f {
  display: flex;
}

.f1 {
  flex: 1;
}

.f3{
  flex: 3;
}

.fd-col{
  flex-direction: column;
}

.fd-row{
  flex-direction: row;
}

.jc-sb {
  justify-content: space-between;
}

.p10{
  padding: 10px;
}

/* ag-grid */
.ag-theme-bars {
  --ag-font-family: 'Neucha', cursive !important;
  --ag-border-radius: 10px !important;
}

.btn-primary{
  background: rgb(60, 140, 177);
}
