.sidemenu--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin: 5px;
    color: #A9B2C4
}
.sidemenu--item:hover {
    color: rgb(60, 140, 177);
    /* #EE2F48; */
    border-radius: 5;
}

.icon {
    margin-right: 10px
}