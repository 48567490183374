.job--poster{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #FFFFFF;
    padding: 2rem 2rem;
    border: 0.5px solid #f3f3f3;
    align-items: center;
    border-radius: 10px;
    margin-top: 0.5rem;
}