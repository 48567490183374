.latest--jobs{
    display: flex;
    flex-direction: column;
    width: 40%;
}
.latest--jobs .latest--jobs--list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 40rem;
    flex-wrap: wrap;
}

.latest--job{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}
.latest--job .latest--job--title{
    cursor: pointer;
    color: rgb(60, 140, 177);
    font-weight: 600;
}
.latest--job .job--data{
    display: flex;
    flex-direction: row;
}
.latest--job .job--data > *{
    margin-right: 3px;
}

.latest--job .job--data .latest--job--company {
    cursor: pointer;
}