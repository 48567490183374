.google--login{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0.25rem 0;
    padding: 0.25rem;
    border: 1px solid #A9B2C4;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 40%;
}

.google--login:hover{
    background-color: #1D2435;
    color: #A9B2C4;
}