.login--form{
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 1rem;
    height: 100%;
}

.informative{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    border-right: 3px solid #A9B2C4;
}

.app--desc{
    margin-top: 4rem;
}

.form--container{
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    margin: 0 1rem;
    width: 45%;
}

.form--title{
    font-weight: 700;
}

.social--login{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.login--controls{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}