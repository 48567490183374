.sidemenu {
    display: flex;
    height: 3rem;
    border-bottom: 0.5px solid #f3f3f3;
}

.sidemenu--container {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
}

.sidemenu--container .header--part {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidemenu--container .sidemenu--controls{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3rem;
}